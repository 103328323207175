const { initLandingSampleSizes } = require('./forms/landingSampleForm');
const { onFormSend } = require('./forms/ajax');
const { onInputTextAreaInteraction, onInteraction, onSampleSizeInteraction } = require('./forms/interactions');
const { analyticsSampleRequest } = require('./analytics/listeners/externallyTriggeredEvents');
const { HMSDatePicker } = require('./initDatepicker');

window.HMSForm = class Form {
  constructor() {
    this.forms = new Map();
    this.init();
  }

  /**
   * Registers forms by looping through the dom, and adds the forms to the forms map.
   */
  registerForms() {
    $.each($('.js-form'), (index, form) => {
      this.forms.set(form.id, {
        type: $(form).data('formtype'),
        isValid: false,
        serverMessage: false,
        elements: null,
        inputTimeout: false,
        hasRecaptcha: $(form).find('.g-recaptcha.js-form-content').length > 0
      })
    });
  }

  /**
   * Gets form data from the forms map
   * @param {String} formId
   * @returns {Object} - Form data
   */
  getFormData(formId) {
    return this.forms.get(formId);
  }

  /**
   * Sets a new data to the desired form in the forms map
   * @param {String} formId
   * @param {*} update - new form data
   */
  setFormData(formId, update) {
    const data = this.getFormData(formId);

    Object.keys(update).forEach(key => {
      data[key] = update[key];
    });
    this.forms.set(formId, data);
  }

  /**
   * Gets form id from the containing dom element
   * @param {Node} element
   * @returns {String} - Form id
   */
  getFormIdFromElement(element) {
    return $(element).parents('.js-form').attr('id');
  }

  /**
   * Inits the forms by initing datepickers, dropdowns, and adds forms elements event listeners
   */
  init() {
    window.HMS.DatePicker = new HMSDatePicker();
    this.registerForms();
    this.addEvents();
    if (window.productsAndSizes && $('.js-form-landing-sample').length) {
      initLandingSampleSizes();
      analyticsSampleRequest('Step 1', $('#LandingSampleProduct').val());
    }
  }

  /**
   * Adds form elements event listeners
   */
  addEvents() {
    $('.js-form-submit').on('click', event => onFormSend(event, this));

    $(document).on('change', '.js-form-submit-onchange', event => onFormSend(event, this));

    $('.js-form input, .js-form textarea').on('keypress change focusout click paste',
      event => onInputTextAreaInteraction(event, this));

    $('.js-form .js-select').on('select2:close change', event => onInteraction(event.currentTarget, this));

    $('.js-form-sample-size').on('change', event => onSampleSizeInteraction(event.currentTarget));
  }
};
