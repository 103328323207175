import { scrollToElement } from '../helpers/scrollToElement';

/**
 * Clears the field related validation error
 * @param {Node} $formField
 */
function clearError($formField) {
  $formField.find('.validate-message').removeClass('u-display-block');
  $formField.find('.field-checkbox-container').removeClass('invalid');
  $formField.find('js-validation-icon').remove();
}

/**
 * Clears all form errors
 * @param {Node} $form
 */
function clearErrors($form) {
  $.each($form.find('.js-form-field'), (index, formField) => clearError($(formField)));
}

/**
 * Toggles field related notification
 * @param {Object} element
 * @param {Node} $form
 */
export function toggleNotification(element, $form) {
  if (element.id === 'g-recaptcha') {
    $form.find('.captcha-error').toggleClass('hide', element.validation.valid);

    return;
  }
  const isValid = element.validation.valid;

  if ($form.hasClass('js-form-sample') && element.type === 'sample-sizes') {
    $('.js-form-sample-size-validation').toggleClass('u-display-block', !isValid);
  }
  const $formField = $form.find(`#${element.id}`).parents('.js-form-field');

  $formField.find('.field-checkbox-container').toggleClass('invalid', !isValid);
  $formField.find('.js-validation-icon').remove();
  $formField.find('.validate')
    .toggleClass('invalid', !isValid)
    .toggleClass('valid', isValid)
    .append(`<i class="js-validation-icon icon-validation-${isValid ? 'tick' : 'cross'}"></i>`);
  const requredFailed = element.validation.failedRules.indexOf('validate-required') > -1;
  function toggleMsg(validation, toggle) {
    $formField.find(`.${validation}-message`).toggleClass('u-display-block', toggle);
  }
  function hideAllFieldMsgs() {
    $formField.find('.validate-message').removeClass('u-display-block');
  }

  const emailMatchCheckFailed = element.type === 'email' && element.validation.failedRules.indexOf('validate-match') > -1;
  if (emailMatchCheckFailed) {
    // Do not show other notifications if emails do not match
    hideAllFieldMsgs();
    toggleMsg('validate-match', true);


    return;
  }

  if (requredFailed) {
    // Do not show other notifications if required check failed
    hideAllFieldMsgs();
    toggleMsg('validate-required', true);

    return;
  }

  element.validation.validations.forEach(validation => {
    toggleMsg(validation, element.validation.failedRules.indexOf(validation) !== -1);
  });
}

/**
 * Toggles all notifications in the form
 * @param {Node} $form
 * @param {Array} elements - form elements data
 */
export function toggleNotifications($form, elements) {
  let navigatedToError = false;

  clearErrors($form);

  elements.forEach(element => {
    toggleNotification(element, $form);
    if (!navigatedToError && !element.validation.valid) {
      navigatedToError = true;
      const $element = $(`#${element.id}`);
      if ($element.length) {
        scrollToElement($element.parents('.js-form-field'));

        return;
      }

      if (element.id === 'g-recaptcha') {
        scrollToElement($form.find('.g-recaptcha'));
      }
    }
  });
}
