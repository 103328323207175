const { toggleNotification } = require('./notifications');
const { getElementProps } = require('./getElementProps');
const { showServerMsg } = require('./ajax');
const { allowOnlyNumbersInInput } = require('../helpers/allowOnlyNumbersInInput');
const { addNumberInputMaxLength } = require('../helpers/addNumberInputMaxLength');

/**
 * 
 * @param {Node} $target
 */
function toggleOptins($target) {
  const showIfChecked = $target.data('show-if-checked');
  const hideIfChecked = $target.data('hide-if-checked');
  if (!showIfChecked && !hideIfChecked) {
    return;
  }
  if (showIfChecked && showIfChecked.length && $target[0].checked) {
    $(`#optin-group-${showIfChecked}`).removeClass('hide');

    return;
  }
  $(`#optin-group-${hideIfChecked || showIfChecked}`).addClass('hide');
  $(`#optin-group-${hideIfChecked || showIfChecked}`).find('.js-form-element').each((index, input) => {
    input.checked = false;
  });
}

/**
 * Callback when the user interacts with the form
 * @param {Node} target - event.currentTarget
 * @param {Object} classObj - Forms class instance
 */
export function onInteraction(target, classObj) {
  const $target = $(target);
  const $form = $target.parents('.js-form');

  toggleOptins($target);
  toggleNotification(getElementProps(target, classObj), $form);
}

/**
 * On Sample page, the product size dropdown (displayed outside the form) is validated as mandatory.
 * This method toggles sample size validation message.
 * @param {Node} target - event.currentTarget
 */
export function onSampleSizeInteraction(target) {
  $('.js-form-sample-size-validation').toggleClass('u-display-block', $(target).val() === '');
}

/**
 * Callback when the input/textarea is being used by the user.
 * @param {Event} event - text area event
 * @param {Object} classObj - Forms class instance
 */
export function onInputTextAreaInteraction(event, classObj) {
  if (event.type === 'keypress' && event.currentTarget.type === 'number') {
    allowOnlyNumbersInInput(event);

    if (event.currentTarget.maxLength) {
      addNumberInputMaxLength(event);
    }
  }

  const formId = classObj.getFormIdFromElement(event.currentTarget);
  let formData = classObj.getFormData(formId);

  if (formData.serverMessage === true) {
    showServerMsg(false, $(event.currentTarget).parents('.js-form'));
  }

  if (formData.inputTimeout) {
    clearTimeout(formData.inputTimeout);
    formData.inputTimeout = null;
    classObj.forms.set(formId, formData);
  }

  formData.inputTimeout = setTimeout(() => onInteraction(event.currentTarget, classObj), 100);
  classObj.forms.set(formId, formData);
}