import { analyticsFormSend } from '../analytics/listeners/externallyTriggeredEvents';

const { toggleNotifications } = require('./notifications');
const { scrollToElement } = require('../helpers/scrollToElement');
const { preloader } = require('../helpers/preloader');
const { indexFormElements } = require('./indexFormElements');
const { HMSNewsletterSignup } = require('../newsletterSignup');

/**
 * Generates JSON for form sending
 * @param {String} id - Form id
 * @param {Array} elements - form elements
 * @returns {JSON}
 */
function generateJSON(id, elements) {
  const $form = $(`#${id}`);

  /**
   * Strips form id prefix if present in element id / name, since these fields are later used
   * to form json
   * @param {Object} element
   */
  function stripFormIdPrefix(element) {
    const splitId = element.id.split(`${id}__`);
    const splitName = element.name ? element.name.split(`${id}__`) : [];

    if (splitId.length === 2) {
      element.id = splitId[1];
    }

    if (element.name && splitName.length === 2) {
      element.name = splitName[1];
    }
  }

  /**
   * Some forms have prefixed id's that should be stripped in the save json.
   * This function stips the prefix for forms that have attribute data-has-prefixed-id.
   * @returns {String} id for json
   */
  function getFormIdforJSON() {
    if ($form.data('has-prefixed-id')) {
      const stippedId = id.split('__');

      if (stippedId.length === 2) {
        return stippedId[1];
      }
    }

    return id;
  }

  let json = {
    formId: getFormIdforJSON(),
    formParentId: $form.parents('.js-form-parent').attr('id')
  };

  let checkboxGroups = new Set();

  // Add form fields data
  elements.forEach(element => {
    if (!element.value) {
      return
    }

    stripFormIdPrefix(element);

    if (element.id === 'g-recaptcha') {
      json.isRecaptchaValidated = true;
      json.responseKey = element.value;
    } else if (element.value.datepicker) {
      json[element.id] = `${element.value.datepicker}`;
    } else if (element.type === 'radio') {
      json[element.name] = element.value;
    } else if (element.type === 'checkbox') {
      if (element.checkboxGroupId) {
        checkboxGroups.add(element.checkboxGroupId);
      } else {
        json[element.name] = element.value;
      }
    } else {
      element.name ? json[element.name] = element.value : json[element.id] = element.value;
    }
  });

  checkboxGroups.forEach(checkboxGroup => {
    json[checkboxGroup] = getGroupValue(checkboxGroup);
  });

  // Add hidden form inputs data
  $.each($form.find('.js-form-hidden-inputs input'), (index, input) => {
    const $input = $(input);
    let inputName = $input.attr('name');
    if (inputName.split(id).length === 2) {
      inputName = inputName.split(`${id}__`)[1];
    }
    json[inputName] = $input.val();
  });

  // hidden input isn't inside form
  if (id === 'form-buy-in-store' ) {
    json.VariantIds = document.getElementById('VariantIds').value;
  }

  return json;
}

function getGroupValue(checkboxGroupId) {
  let groupValue = '';

  $(`input[checkboxGroupId='${checkboxGroupId}']:checked`).each((index, checkbox) => {
    let labelValue = $(`label[for='${$(checkbox).attr('name')}']`).text().trim();
    if (index === 0) {
      groupValue += labelValue;
    } else {
      groupValue += `|${labelValue}`;
    }
  });

  return groupValue;
}

/**
 * When the form is sent, the user gets scrolled to the top of the form.
 * This function returns the scrollable element depending of the form type.
 * @param {Node} $form
 * @returns {Node} - Element to scroll to
 */
function getScrollableElement($form) {
  switch ($form.data('formtype').toLowerCase()) {
    case 'sampleorder':
      return $form.hasClass('js-form-landing-sample')
        ? $form.parents('.js-landing-sample-content')
        : $form.parents('.js-sample-container');
    case 'newsletter':
      return $form.parents('.js-popup-container').length
        ? $form.parents('.js-popup-container')
        : $form.parents('.crate');
    case 'contactus':
      return $form.parents('.crate').length ? $form.parents('.crate') : $form;
    case 'askdoctor':
      return $form.parents('.crate');
    default:
      return $form;
  }
}

/**
 * Shows the server message after the form has been sent.
 * @param {Boolean} toggle Hide/Show the message
 * @param {Node} $form
 * @param {String} msg server message
 * @param {String} msgType msg status from the server (success/error)
 */
export function showServerMsg(toggle, $form, msg, msgType) {
  // eslint-disable-next-line no-negated-condition
  if (!$form.hasClass('js-iri-form')) {
    scrollToElement(getScrollableElement($form));
  }

  let $sendButton = $form.find('.js-form-send-btn');

  // Fix for buttons outside the form
  if (!$sendButton.length) {
    const formId = $form.attr('id');
    $sendButton = $(`.js-form-submit[data-formid="${formId}"]`).parents('.js-form-send-btn');
  }

  if (msgType === 'success') {
    if ($form.hasClass('js-form-sample')) {
			window.HMSProductSample.renderStep(msg);
			$('.js-sample-selected-info').remove();

      return;
    }
    if ($form.hasClass('js-form-landing-sample')) {
      $form.parents('.js-form-parent').remove();
      $sendButton.remove();
      $('.js-landing-sample').addClass('landing-sample--confirmation');
      $('.js-landing-sample-text').html(msg);

      return;
    }
    if ($form.hasClass('js-form-newsletter-signup')) {
      HMSNewsletterSignup.renderResponse(msg, $form);
      if ($form.hasClass('js-form-newsletter-signup-popup')) {
        HMSNewsletterSignup.setCookie();
      }

      return;
    }
  }
  const $serverMsg = $form.find('.js-form-server-msg');

  if (!toggle) {
    $form.find('.js-form-content').removeClass('hide');
    $sendButton.removeClass('hide');
    $serverMsg.html('').removeClass('fontRed').addClass('hide');

    return;
  }

  $serverMsg.html(msg).removeClass('hide');
  if (msgType === 'success') {
    if (!$form.hasClass('js-iri-form')) {
      $form.find('.js-form-content').addClass('hide');
      $sendButton.addClass('hide');
    }
  }
  if (msgType === 'error') {
    $serverMsg.addClass('fontRed');
  }
}

/**
 * Actions after the form is sent succesfully.
 * @param {Node} $form
 * @param {Object} data data got from the server responce
 * @param {String} textStatus text status got from the server responce
 * @param {Object} classObj Forms class instance
 * @param {Object} payload Data passed from ajax post
 */
// eslint-disable-next-line max-params
function onSendSuccess($form, data, textStatus, classObj, payload) {
  preloader($form, false);
  showServerMsg(true, $form, data, textStatus);
  classObj.setFormData($form.attr('id'), { serverMessage: true });
  analyticsFormSend($form);

  // Reinitialize power reviews script for Checkout Beacon to work
  const $sampleConfirmation = $('.js-sample-form-status');
  if ($sampleConfirmation.length && $sampleConfirmation.data('form-status') === 'Confirmation') {
    new window.HMSPowerReviews();
  }

  if ($form.attr('id') === 'form-buy-in-store') {
    window.HMSBuyInStoreInstance.render(data);
  }

  if ($form.attr('id') === 'form-where-to-buy') {
    window.HMSBuyInStoreInstance.render(data);
    window.HMSWhereToBuyInstance.init(data);
    if (data.results.IriStores.length === 0) {
      window.HMSWhereToBuyInstance.initBuyDirect(payload.product, payload.segment);
    }
  }
}

/**
 * Actions after the form failed to be saved.
 * @param {Node} $form
 * @param {Object} jqXHR Server responce
 * @param {Object} classObj Forms class instance
 */
function onSendFail($form, jqXHR, classObj) {
  const genericServerFailMsg = $form.find('.js-form-server-fail').html();
  showServerMsg(true, $form, jqXHR.responseText || genericServerFailMsg, 'error');
  preloader($form, false);
  classObj.setFormData($form.attr('id'), { serverMessage: true });
}

/**
 * Callback when the form send button is pressed.
 * If the form is valid, it will be sent.
 * @param {Event} event - Click event
 * @param {Object} classObj - Forms class instance
 * @returns {Function | null} - If Recaptcha was removed by hackers return function call
 */
export function onFormSend(event, classObj) {
  event.preventDefault();
  const $button = $(event.currentTarget);
  let $form = $button.parents('.js-form');

  // Fix for buttons outside the <form>
  if (!$form.length && $button.data('formid') && $button.data('formid').length) {
    $form = $(`#${$button.data('formid')}`);
  }

  indexFormElements($form, classObj);

  const id = $form.attr('id');
  const formData = classObj.getFormData(id);
  const hasRecaptcha = classObj.forms.get(id).hasRecaptcha;
  const payload = generateJSON(id, formData.elements);
  let isValid = formData.isValid;

  if (isValid && hasRecaptcha && (!payload.responseKey || !payload.isRecaptchaValidated)) {
    isValid = false;

    // Recaptcha was present on page but was removed by hackers before sending.
    return onSendFail($form, false, classObj);
  }

  if (isValid) {
    preloader($form, true);

    $.ajax({
      url: $form.attr('action'),
      type: 'POST',
      data: payload,
      success: (data, textStatus) => onSendSuccess($form, data, textStatus, classObj, payload),
      error: jqXHR => onSendFail($form, jqXHR.responseText, classObj)
    });
  }

  toggleNotifications($form, formData.elements);
}
