import { getElementProps } from './getElementProps';

/**
 * Indexes the form elements from the DOM, and sets the value to the Forms map in the main class.
 * @param {Node} $form
 * @param {Object} classObj - Form class instance
 */
export function indexFormElements($form, classObj) {
  const elements = [];
  const formId = $form.attr('id');
  classObj.setFormData(formId, { isValid: true });

  $.each($form.find('.js-form-field'), (index, field) => {
    const $field = $($(field).find('.js-form-element'));

    $field.each((index2, element) => {
      elements.push(getElementProps(element, classObj, field));
    });
  });

  const $recaptchaResponse = $form.find('.g-recaptcha-response');

  if ($recaptchaResponse.length) {
    const value = $recaptchaResponse.val().trim();
    const isValid = value !== '';

    const element = {
      id: 'g-recaptcha',
      value,
      mandatory: true,
      validation: {
        valid: isValid,
        validations: ['validate-required']
      }
    };

    if (!isValid) {
      classObj.setFormData(formId, { isValid: false });
      element.validation.failedRules = element.validation.validations;
    }

    elements.push(element);
  }

  if ($form.hasClass('js-form-sample')) {
    const value = $('.js-form-sample-size').val();
    const valid = value !== '';
    if (!valid) {
      classObj.setFormData(formId, { isValid: false });
    }
    const failedRules = valid ? [] : ['validate-required'];

    elements.push({
      type: 'sample-sizes',
      name: 'sample-sizes',
      id: 'AvailableSizesOfSelectedItem',
      value,
      validation: {
        valid,
        failedRules,
        validations: ['validate-required']
      }
    })
  }

  classObj.setFormData(formId, { elements	});
}
