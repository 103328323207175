/**
 * Validates if the sting is in valid email form.
 * @param {String} email
 * @returns {Boolean}
 */
export const validateEmail = email => {
  // Fix for invalid characters after the '@' sign in Chrome.
  if (email.indexOf('xn--') > -1) {
    return false;
  }

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return re.test(email);
};
