const { validateEmail } = require('../helpers/validateEmail');
const { validateEmoji } = require('../helpers/validateEmoji');

/**
 * Get field validations from the dom
 * @param {Node} field
 * @returns {Array} validations list
 */
function getFieldValidations(field) {
  const classes = [...field.classList];

  return classes.filter(className => className.indexOf('validate-') > -1);
}

/**
 * Validates the element
 * @param {Node} field - Field node
 * @param {String} type - Field type
 * @param {String} value - Field value
 * @param {Object} classObj - Forms class instance
 * @returns {Object} field validation data
 */
export const validateElement = (field, type, value, classObj) => {
  const $field = $(field);
  const formId = $field.parents('.js-form').attr('id');
  const validations = getFieldValidations(field);
  let valid = true;
  const failedRules = [];

  /**
   * Pushed data for invalid field validation
   * @param {String} validation
   */
  const pushFailed = validation => {
    valid = false;
    failedRules.push(validation);
    classObj.setFormData(formId, { isValid: false });
  }

  const isRequired = validations.indexOf('validate-required') > -1;

  // eslint-disable-next-line complexity
  const validateField = validation => {
    switch (validation) {
      case 'validate-required':
        if (!value || value.length === 0) {
          if (type === 'checkbox') {
            if ($field.find('.js-form-element:checked').length
              || ($field.hasClass('js-optin-group') && $field.hasClass('hide'))) {

              return;
            }
          }
          pushFailed(validation);
        }
        break;
      case 'validate-email':
        if (!validateEmail(value) && (isRequired && value !== '')) {
          pushFailed(validation);
        }
        break;
      case 'validate-match':
        if ($(`#${$field.data('match')}`).val().trim() !== value) {
          pushFailed(validation);
        }
        break;
      case 'validate-min':
        if (value.length < $field.find('.js-form-element').attr('minlength')
            && (isRequired && value !== '')) {
          pushFailed(validation);
        }
        break;
      case 'validate-max':
        if (typeof Number(value) === 'number') {
          const $input = $field.find('.js-form-element');
          const maxLength = $input.attr('maxlength');

          if (value.length > Number(maxLength)) {
            const trimmedValue = $input.val().substring(0, Number(maxLength));
            $input.val(trimmedValue);
          }
        }
        break;
      case 'validate-emoji':
        if (!validateEmoji(value)) {
          pushFailed(validation);
        }
        break;
      case 'validate-prefix': {
        const $input = $field.find('.js-form-element');
        if (!$input.val().trim().startsWith($input.attr('mobileprefix'))) {
          pushFailed(validation);
        }
        break;
      }
      default:
        break;
    }
  };

  validations.forEach(validation => validateField(validation));

  return { valid, failedRules, validations };
};
