import { Select } from '../common/select';

/**
 * Generates sample size options
 * @param {Array} sizes - Available product sizes
 * @returns {String}
 */
const generateSizeOptions = sizes => {
  let options = '';
  sizes.forEach((size, index) => {
    options += `<option value="${size.SizeValue}" ${index === 0 ? 'selected' : ''}>${size.Size}</option>`;
  });

  return options;
};

/**
 * Renders product size dropdown
 * @param {Array} sizes - Available product sizes
 * @param {Node} $selectedProduct
 */
const renderProductSizesDropdown = (sizes, $selectedProduct) => {
  const sizeLabelVal = $('#SelectSizeLabel').val();
  const sizeLabel = sizeLabelVal.length ? `<label class="field-label" for="sampleProductSizes">${sizeLabelVal}</label>` : '';
  $selectedProduct.closest('.row').append(`
  <div class="form-field js-form-field js-form-field-sizes col-6 col-xs-12">
    ${sizeLabel}
    <select name="sampleProductSizes" class="field field-select js-form-element js-select" id="sampleProductSizes">
      ${generateSizeOptions(sizes)}
    </select>
  </div>
  `);
  const $size = $('#sampleProductSizes');
  $size.select2(Select.getSelectConfig($size));
};

/**
 * Callback after the product sample is selected
 * @param {Sting} selectedProduct - product id
 * @param {Node} $selectedProduct
 */
const onLandingSampleProductSelected = (selectedProduct, $selectedProduct) => {
  $('.js-form-field-sizes').remove();
  const sizes = window.productsAndSizes[selectedProduct];
  if (sizes && sizes.length) {
    renderProductSizesDropdown(sizes, $selectedProduct);
  }
};

/**
 * Inits landing sample sizes
 */
export const initLandingSampleSizes = () => {
  if (window.productsAndSizes && Object.keys(window.productsAndSizes).length) {
    const $selectedProduct = $('#AvailableSampleProducts');
    if ($selectedProduct.length) {
      onLandingSampleProductSelected($selectedProduct.val(), $selectedProduct);
    }
    $selectedProduct.on('select2:select', event =>
      onLandingSampleProductSelected(event.params.data.id, $selectedProduct));
  }
};