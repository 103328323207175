/**
 * Representation of the datepicker functionality
 */
export class HMSDatePicker {
  constructor() {
    this.config = {
      enableTime: false,
      dateFormat: 'm/d/Y',
      disableMobile: true
    };
    this.datePickers = new Map();
    this.init();
  }

  /**
   * Initializes the datepickers and stores them in this.datePickers for further use
   * @param {Node} datepicker
   */
  initDatepicker(datepicker) {
    const $datepicker = $(datepicker);
    const yearMin = $datepicker.attr('yearMin');
    const yearMax = $datepicker.attr('yearMax');
    const dateFormat = $datepicker.attr('dateFormat');
    const config = $.extend({}, this.config, true);
    const indexOfMonth = 11;
    const dayOfMonth = 31;

    if (yearMin) {
      config.minDate = new Date(yearMin, 0);
    }

    if (yearMax) {
      config.maxDate = new Date(yearMax, indexOfMonth, dayOfMonth);
    }

    if (dateFormat) {
      config.dateFormat = dateFormat;
    }

    this.datePickers.set($datepicker.attr('id'), $datepicker.flatpickr(config));
  }

  /**
   * Gathers all datepickers and initializes them by calling this.initDatepicker(datepicker)
   */
  init() {
    $.each($('.js-datepicker'), (index, datepicker) => this.initDatepicker(datepicker));
  }
}