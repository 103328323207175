import { validateElement } from './validation';

/**
 * Gets the value of the form element.
 * The value is returned as a string, besides the datepicker that returns the value as an object.
 * @param {Node} $element - Form element
 * @returns {(String|Object)} - Element value
 */
function getValue($element) {
  if ($element.attr('type') === 'checkbox') {
    return $element.attr('checkboxGroupId') && $element.attr('checkboxGroupId').length
      ? $(`label[for='${$element.attr('name')}']`).text().trim()
      : $element[0].checked;
  }

  if ($element.attr('type') === 'radio') {
    let value;
    $.each($element, (index, radio) => {
      if (radio.checked) {
        value = $(radio).val();
      }
    });

    return value;
  }

  if ($element.hasClass('js-datepicker')) {
    const fp = $element[0]._flatpickr;
    const selectedDate = fp.selectedDates[0];

    if (selectedDate) {
      return {
          datepicker: fp.formatDate(selectedDate, 'Y-d-m')
        };
    }

    return '';
  }

  return $element.val() ? $element.val().trim() : '';
}

/**
 * Gets the element props from the DOM
 * @param {Node} element
 * @param {Object} classObj
 * @param {Node} field - element js-form-field parent
 * @returns {Object}
 */
export function getElementProps(element, classObj, field = $(element).parents('.js-form-field')[0]) {
  const $element = $(element);
  const value = getValue($element);
  const tagName = $element[0].tagName;
  const type = $element.attr('type');
  const name = $element.attr('name');
  const id = $element.attr('id');
  const mandatory = $element.hasClass('js-mandatory');
  const validation = validateElement(field, type, value, classObj);
  const checkboxGroupId = $element.attr('checkboxgroupid');

  return { id, tagName, type,	value, name, mandatory,	validation, checkboxGroupId };
}