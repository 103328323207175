import { getCookie, setCookie } from './helpers/cookie';

/**
 * @class HMSNewsletterSignup
 */
export class HMSNewsletterSignup {
  /**
   * Render response html
   * @param {String} msg
   * @param {Node} $form
   */
  static renderResponse(msg, $form) {
    $(`.js-newsletter-signup-text-${$form.attr('id')}`).html(msg);
    $form.parents('.js-form-parent').remove();
  }

  /**
   * Sets cookie that user subscribed
   */
  static setCookie() {
    setCookie('newsletterSignup', true, 3650);
  }

  /**
   * Returns if the user already subscribed
   * @returns {Boolean}
   */
  static isCookieAccepted() {
    return getCookie('newsletterSignup') === 'true';
  }
}
