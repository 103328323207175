const defaultRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

/**
 * Validates if the string contains emojis.
 * If available, regex exposed in window.HMSeMojiRegxExpression,
 * if not the defaultRegex will be used instead.
 * @param {String} fieldValue
 * @returns {Boolean}
 */
export const validateEmoji = fieldValue => {
  const regex = window.HMSeMojiRegxExpression && window.HMSeMojiRegxExpression.length ?
    window.HMSeMojiRegxExpression : defaultRegex;

  let expr = new RegExp(regex);

  if (expr.test(fieldValue)) {
    return false;
  }

  return true;
};
